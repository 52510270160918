import { render, staticRenderFns } from "./EmailLogin.vue?vue&type=template&id=72507c01&scoped=true&"
import script from "./EmailLogin.vue?vue&type=script&lang=ts&"
export * from "./EmailLogin.vue?vue&type=script&lang=ts&"
import style0 from "./EmailLogin.vue?vue&type=style&index=0&id=72507c01&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72507c01",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CaTitle: require('/app/node_modules/@petlove/caramelo-vue-components/src/Title/index.vue').default,AuthenticationProviderGoogle: require('/app/packages/auth/components/AuthenticationProviderGoogle.vue').default,AuthenticationProviderMicrosoft: require('/app/packages/auth/components/AuthenticationProviderMicrosoft.vue').default,AuthenticationProviderApple: require('/app/packages/auth/components/AuthenticationProviderApple.vue').default,AuthenticationProviderFacebook: require('/app/packages/auth/components/AuthenticationProviderFacebook.vue').default,CaDivisor: require('/app/node_modules/@petlove/caramelo-vue-components/src/Divisor/index.vue').default,InputAutocomplete: require('/app/packages/auth/components/InputAutocomplete.vue').default,CaButton: require('/app/node_modules/@petlove/caramelo-vue-components/src/Button/index.vue').default})


import { defineComponent } from '@vue/composition-api';

// @ts-ignore
import AppleIcon from '@/assets/icons/apple.svg';
import { initializeApple } from '@/lib/apple';

export default defineComponent({
  components: { AppleIcon },
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  emits: ['login:social'],
  head() {
    if (typeof window !== 'undefined' && window.AppleID) return {};
    return {
      script: [
        {
          async: true,
          defer: true,
          src: 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js'
        }
      ]
    };
  },
  mounted() {
    const { social } = this.$config;
    initializeApple(social.apple);
  },
  methods: {
    async login() {
      try {
        const { authorization } = await window.AppleID.auth.signIn();
        this.$emit('login:social', authorization.code);
      } catch ({ error }: any) {
        if (error === 'popup_closed_by_user') return;
        this.$store.commit('sso/SHOW_SNACKBAR', {
          message: 'OPS! Ocorreu um erro ao fazer o login!'
        });
      }
    }
  }
});

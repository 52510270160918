
// @ts-ignore
import CaInputText from '@petlove/caramelo-vue-components';
import {
  ref,
  onMounted,
  onUnmounted,
  defineComponent
} from '@vue/composition-api';

export default defineComponent({
  model: {
    prop: 'modelValue',
    event: 'input'
  },
  props: {
    modelValue: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const inputRef = ref<InstanceType<typeof CaInputText> | null>(null);
    const optionsRefs = ref<InstanceType<typeof HTMLLIElement>[] | null>(null);

    const focusOnOption = (_event: Event, index = 0) => {
      if (!optionsRefs.value || optionsRefs.value.length === 0) return;

      if (index === optionsRefs.value.length || index < 0) {
        return inputRef.value.$el.firstChild.focus();
      }

      const optionToFocus = props.options[index];

      const refToFocus = optionsRefs.value.find((optionRef: HTMLLIElement) => {
        const optionText = (
          optionRef.firstChild as HTMLSpanElement
        ).innerHTML.trim();

        return optionText === optionToFocus;
      });

      refToFocus?.focus();
    };

    const disableScrollOnArrows = (event: KeyboardEvent) => {
      const isOptionFocused =
        document.activeElement?.classList.contains('dropdown__option');

      if (isOptionFocused && ['ArrowUp', 'ArrowDown'].includes(event.code)) {
        event.preventDefault();
      }
    };

    onMounted(() => {
      window.addEventListener('keydown', disableScrollOnArrows);
    });

    onUnmounted(() => {
      window.removeEventListener('keydown', disableScrollOnArrows);
    });

    return { inputRef, optionsRefs, focusOnOption };
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(newValue: string) {
        this.$emit('input', newValue);
      }
    }
  }
});

// @ts-ignore
import { onLoad } from '@petlove/webstore-plugins/helpers/onLoad';

declare global {
  interface Window {
    FB?: any;
  }
}

const setFacebookCredentials = (key: string) => {
  window.FB?.init({
    appId: key,
    xfbml: true,
    version: 'v5.0'
  });

  window.FB.AppEvents.logPageView();
};

export const initializeFacebook = (key: string) => {
  onLoad(() => {
    setFacebookCredentials(key);
  });
};

// @ts-ignore
import { onLoad } from '@petlove/webstore-plugins/helpers/onLoad';

declare global {
  interface Window {
    AppleID?: any;
  }
}

const setAppleCredentials = (clientId: string) => {
  window.AppleID?.auth.init({
    clientId,
    scope: 'email',
    usePopup: true,
    redirectURI: window.location.origin
  });
};

export const initializeApple = (clientId: string) => {
  onLoad(() => {
    setAppleCredentials(clientId);
  });
};

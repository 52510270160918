import { render, staticRenderFns } from "./AuthenticationProviderApple.vue?vue&type=template&id=182e3797&scoped=true&"
import script from "./AuthenticationProviderApple.vue?vue&type=script&lang=ts&"
export * from "./AuthenticationProviderApple.vue?vue&type=script&lang=ts&"
import style0 from "./AuthenticationProviderApple.vue?vue&type=style&index=0&id=182e3797&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "182e3797",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CaButton: require('/app/node_modules/@petlove/caramelo-vue-components/src/Button/index.vue').default})


import { defineComponent } from '@vue/composition-api';

// @ts-ignore
import FacebookIcon from '@/assets/icons/facebook.svg';
import { IResponseFacebook } from '@/lib/constants';
import { initializeFacebook } from '@/lib/facebook';

export default defineComponent({
  components: { FacebookIcon },
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  emits: ['login:social'],
  head() {
    return {
      script: [
        {
          async: true,
          defer: true,
          src: 'https://connect.facebook.net/pt_BR/sdk.js'
        }
      ]
    };
  },
  mounted() {
    const { social } = this.$config;
    initializeFacebook(social.facebook);
  },
  methods: {
    login() {
      window.FB?.login(
        (response: IResponseFacebook) => {
          if (!response || !response.authResponse) {
            return this.$store.commit('sso/SHOW_SNACKBAR', {
              message: 'OPS! Ocorreu um erro ao fazer o login!'
            });
          }

          this.$emit('login:social', response.authResponse.accessToken);
        },
        { scope: 'email' }
      );
    }
  }
});

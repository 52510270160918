
// @ts-ignore
import { validateEmail } from '@petlove/frontend-utilities-validations';
import { ref, watch, computed, defineComponent } from '@vue/composition-api';
import { mapGetters } from 'vuex';
import { useNamespacedState } from 'vuex-composition-helpers';

// @ts-ignore
import HeartIcon from '@/assets/icons/heart.svg';
import {
  IResponseError,
  IAuthenticationProvider,
  EMAILS_PROVIDERS
} from '@/lib/constants';
import { authenticateWithSocialProvider } from '@/lib/login';
import { WhitelabelState } from '@/store/whitelabel/state';

export default defineComponent({
  components: {
    HeartIcon
  },
  emits: ['loading'],
  setup() {
    const email = ref('');
    const loading = ref(false);
    const inputInvalid = ref(false);
    const inputErrorMessage = ref('E-mail inválido');

    const showProviders = ref(false);
    const providerOptions = Object.values(EMAILS_PROVIDERS);

    const { store: whitelabelStore } = useNamespacedState<WhitelabelState>(
      'whitelabel',
      ['store']
    );

    const accesibleBySSO = computed(() =>
      whitelabelStore.value?.isWhitelabel
        ? whitelabelStore.value?.name
        : 'online da Petlove e os Planos de Saúde'
    );

    const providersFiltered = computed(() => {
      const indexAt = email.value.indexOf('@');
      const after = email.value.slice(indexAt);

      const filtered = providerOptions.filter(
        (provider: string) => provider.includes(after) && provider !== after
      );

      return filtered.slice(0, 5);
    });

    const invalidEmail = computed(() => {
      return !validateEmail(email.value);
    });

    watch([email, providersFiltered], ([newEmail, newProviders]) => {
      inputInvalid.value = false;
      showProviders.value = newEmail.includes('@') && newProviders.length > 0;
    });

    const updateEmailProvider = (provider: string) => {
      const indexAt = email.value.indexOf('@');
      const before = indexAt >= 0 ? email.value.slice(0, indexAt) : email.value;
      email.value = `${before}${provider}`;
    };

    return {
      email,
      loading,
      inputInvalid,
      showProviders,
      inputErrorMessage,
      invalidEmail,
      accesibleBySSO,
      providersFiltered,
      updateEmailProvider
    };
  },
  computed: {
    ...mapGetters({
      googleAuthenticator: 'sso/googleAuthenticator',
      microsoftAuthenticator: 'sso/microsoftAuthenticator',
      appleAuthenticator: 'sso/appleAuthenticator',
      facebookAuthenticator: 'sso/facebookAuthenticator',
      socialAuthenticators: 'sso/socialAuthenticators',
      passwordlessAuthenticator: 'sso/passwordlessAuthenticator'
    }),
    socialFirst(): boolean {
      return this.socialAuthenticators.some(({ order_number = 0 } = {}) => {
        const passwordlessOrder =
          this.passwordlessAuthenticator?.order_number ?? 0;
        return order_number < passwordlessOrder;
      });
    },
    hasSocialAndPasswordless(): boolean {
      return (
        this.socialAuthenticators.length > 0 &&
        Boolean(this.passwordlessAuthenticator)
      );
    }
  },
  methods: {
    isLoadingLogin(value: boolean) {
      this.$emit('loading', value);
    },
    async handleSocialLogin(
      credential: string,
      provider: IAuthenticationProvider
    ) {
      this.isLoadingLogin(true);
      const response = await authenticateWithSocialProvider({
        provider,
        credential,
        component: this
      });

      if (!response) return;
      this.isLoadingLogin(false);
      this.handleError(response);
    },
    async sendPinEmail() {
      if (this.invalidEmail) return (this.inputInvalid = this.invalidEmail);

      this.loading = true;
      const result = await this.$store.dispatch('sso/passwordless', {
        email: this.email
      });

      if (!result) return;
      this.loading = false;
      this.handleError(result);
    },
    handleError({ error }: { error: IResponseError }) {
      const { message } = error ?? {
        message: 'Ops! Ocorreu um erro ao tentar fazer o login'
      };

      if (typeof message === 'string') {
        this.$store.commit('sso/SHOW_SNACKBAR', { message });
      } else if (message.email) {
        this.inputInvalid = true;
        this.inputErrorMessage = message.email[0] ?? 'E-mail inválido';
      }
    }
  }
});


// @ts-ignore
import { customEvent } from '@petlove/webstore-libs/analytics/events/customEvents';
import { defineComponent } from '@vue/composition-api';

// @ts-ignore
import MicrosoftIcon from '@/assets/icons/microsoft.svg';

export default defineComponent({
  components: { MicrosoftIcon },
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  emits: ['login:social'],
  methods: {
    async login() {
      try {
        const { accessToken = '' } = await this.$microsoft.loginPopup({
          scopes: ['email']
        });

        if (!accessToken) {
          throw new Error('OPS! Ocorreu um erro ao fazer o login!');
        }

        await this.$emit('login:social', accessToken);
      } catch (error: unknown) {
        let message = typeof error === 'string' ? error : '';
        if (!message) message = error instanceof Error ? error.message : '';
        if (!message) return;

        const userGaveUp =
          message.startsWith('user_cancelled') ||
          message.startsWith('access_denied');

        if (userGaveUp) return;
        customEvent('login', 'microsoft:falha', 'login');

        this.$store.commit('sso/SHOW_SNACKBAR', {
          message
        });
      }
    }
  }
});

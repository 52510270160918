import { render, staticRenderFns } from "./InputAutocomplete.vue?vue&type=template&id=04bdc460&scoped=true&"
import script from "./InputAutocomplete.vue?vue&type=script&lang=ts&"
export * from "./InputAutocomplete.vue?vue&type=script&lang=ts&"
import style0 from "./InputAutocomplete.vue?vue&type=style&index=0&id=04bdc460&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04bdc460",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CaInputText: require('/app/node_modules/@petlove/caramelo-vue-components/src/InputText/index.vue').default,CaDivisor: require('/app/node_modules/@petlove/caramelo-vue-components/src/Divisor/index.vue').default})
